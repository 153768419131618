<template src="./completeinteracpsigate.html"></template>
<style scoped src="./completeinteracpsigate.css"></style>
<script>
import Loading from 'vue-loading-overlay'
import TransactionService from '@/services/TransactionService.vue'
import moment from 'moment'
import { requiredIf } from 'vuelidate/lib/validators'
import applicationConstants from '@/constants/AppConstants.vue'
import Utils from '@/utils/utils'
import modal from '@/components/modal/notification/modal'
import pagination from '@/components/vtable/vtpagination.vue'

export default {
    components : {
      Loading, modal, pagination
    },
    data () {
        return {
            searchBy1:'',
            searchBy2:'allTime',
            searchValue:'',
            loaderVisible:false,
            transactionSummary: [],
            errorMessages: applicationConstants.formsMessages,
            columns :['DateTime','ParentReferenceNumber','TransactionId','ReferenceNumber','SenderName','SourceCurrency','SenderEmail','RecipientName','Amount','Action'],
            options :{
                headings : {
                    ParentReferenceNumber :'Parent Reference Number',
                    TransactionId: 'Transaction ID',
                    ReferenceNumber:'Reference Number',
                    SenderName: 'Sender Name' ,
                    SourceCurrency:'Source Currency',
                    SenderEmail:'Sender Email',
                    RecipientName : 'Recipient Name',
                }
            },
            Utils : Utils,
            alertObject: {
                errorCode: null,
                errorDescription: null,
                errorDetails: []
            },
            isLazyLoadingEnabled: applicationConstants.enableLazyLoading
        }
    },
    methods : {
        async showAllTransaction() {
            try {
                this.loaderVisible=true
                this.$v.$reset()
                this.searchValue = null
                let res = await TransactionService.methods.GetTransactionSummary({
                    FundingSource : '1',
                    TransactionStatus : '35',
                    TransactionType : '2'
                })
                this.transactionSummary = res.csTransactionSummaryResponse.TransactionSummary.csTransactionSummaryItem
                if (this.transactionSummary) {
                    this.transactionSummary = Array.isArray(this.transactionSummary) ? this.transactionSummary : [this.transactionSummary]
                } else {
                    this.transactionSummary = []
                }
                if(this.isLazyLoadingEnabled) {
                    this.$refs.paginationRef.selectedPage = 1
                }
                this.loaderVisible=false
            } catch (error) {
                //
            }
        },
        async showTransaction() {
            try {
                this.$v.searchValue.$touch()
                if(this.$v.searchValue.$invalid){
                    return
                }
                this.loaderVisible=true
                let customerEmail = this.searchBy1 === 'senderEmail' ? this.searchValue : null
                let parentReferenceNumber = this.searchBy1 === 'parentReferenceNumber' ? this.searchValue : null
                let referenceNumber = this.searchBy1 === 'referenceNumber' ? this.searchValue : null
                let transactionId = this.searchBy1 === 'transactionId' ? this.searchValue : null
                let transactionDate=null, transactionDateTo = null
                if(this.searchBy2 === 'last30Minutes') {
                    transactionDate =  this.localToUTC(moment(moment(Date.now()).subtract(30, 'Minutes')._d).format('MM-DD-YYYY hh:mm:ss A'))
                    transactionDateTo = this.localToUTC(moment(moment(Date.now())._d).format('MM-DD-YYYY hh:mm:ss A'))
                }else if (this.searchBy2 === 'lastDay') {
                    transactionDate =  this.localToUTC(moment(moment(Date.now()).subtract(1, 'days')._d).format('MM-DD-YYYY hh:mm:ss A'))
                    transactionDateTo = this.localToUTC(moment(moment(Date.now())._d).format('MM-DD-YYYY hh:mm:ss A'))
                }
                let res = await TransactionService.methods.GetTransactionSummary({
                    FundingSource : '1',
                    TransactionStatus : '35',
                    TransactionType : '2',
                    CustomerEmail : customerEmail,
                    ParentReferenceNumber : parentReferenceNumber,
                    ReferenceNumber : referenceNumber,
                    TransactionId : transactionId,
                    TransactionDate : transactionDate,
                    TransactionDateTo : transactionDateTo
                })
                this.transactionSummary = res.csTransactionSummaryResponse.TransactionSummary.csTransactionSummaryItem
                if (this.transactionSummary) {
                    this.transactionSummary = Array.isArray(this.transactionSummary) ? this.transactionSummary : [this.transactionSummary]
                } else {
                    this.transactionSummary = []
                }
                if(this.isLazyLoadingEnabled){
                    this.$refs.paginationRef.selectedPage = 1
                }
                this.loaderVisible=false
            } catch (error) {
                this.loaderVisible=false
            }
        },
        ClickEvent(transactionDetails, type) {
            if(['review', 'complete'].includes(type)) {
                this.$bvModal.msgBoxConfirm('Are you sure you want to ' + type + ' interac Transaction '+transactionDetails.TransactionId)
                .then(result => {
                    if(result) {
                        this.$router.push({
                            name : 'SaltReview',
                            params : {
                                TransactionId : transactionDetails.TransactionId,
                                ReferenceNumber : transactionDetails.ReferenceNumber,
                                ParentReferenceNumber : transactionDetails.ParentReferenceNumber,
                                SenderEmail : transactionDetails.SenderEmail,
                                CompleteInteracPSIGateParams : {
                                    searchBy1 : this.searchBy1,
                                    searchBy2 : this.searchBy2,
                                    searchValue : this.searchValue
                                }
                            }
                        })
                    }
                })
            }else if (type === 'reject'){
                this.$bvModal.msgBoxConfirm('Are you sure you want to ' + type + ' the salt: '+transactionDetails.TransactionId)
                .then( result => {
                    if(result) {
                        this.loaderVisible = true
                        if(!transactionDetails.ReferenceNumber) {
                            TransactionService.methods.GetTransactionDeatils({
                                CustomerEmail : transactionDetails.SenderEmail,
                                TransactionId : transactionDetails.TransactionId
                            })
                            .then( res => transactionDetails.ReferenceNumber = res.csTransactionDetailsResponse.TransactionData.ReferenceNumber)
                            .catch(er => {
                                this.alertObject = er
                                this.$bvModal.show(this.$refs.childModal.id)
                            })
                        }
                        TransactionService.methods.CancelOpTransaction({
                            ReferenceNumber : transactionDetails.ReferenceNumber,
                            CustomerEmail : transactionDetails.SenderEmail
                        }).then(() => this.showAlert(1, 'Transaction Cancelled Successfully'))
                        .catch(error => {
                            this.alertObject = error
                            this.$bvModal.show(this.$refs.childModal.id)
                        })
                        this.loaderVisible = false
                    }
                })
            }
        },
        removeUnderScoreText(data) {
            if(data) {
                data = Array.isArray(data) ? data : [data]
                data.forEach(transaction => {
                    Object.keys(transaction).map(key => {
                        transaction[key] = transaction[key]._text ? transaction[key]._text : null
                        if(key === 'DateTime')
                            transaction[key] = Utils.methods.UTCtoLocal(moment(moment(transaction['DateTime'], 'MM/DD/YYYY hh:mm:ss')._d).format('MM-DD-YYYY HH:mm:ss'))
                    })
                })
            }else{
                data = []
            }
            return data
        },
        showAlert(code, description) {
            this.alertObject.errorCode = code
            this.alertObject.errorDescription = description
            this.alertObject.errorDetails = []
            this.$bvModal.show(this.$refs.childModal.id)
        },
        localToUTC(datte) {
            return moment(datte, 'MM-DD-YYYY HH:mm:ss A').utc().format("MM/DD/YYYY HH:mm:ss")
        }
    },
    validations : {
        searchValue :{
            required: requiredIf(function () {
                return this.searchBy1 != ''
            })
        }
    },
    created () {
        if(this.$route.params.CompleteInteracPSIGateParams) {
            this.searchBy1 = this.$route.params.CompleteInteracPSIGateParams.searchBy1
            this.searchBy2 = this.$route.params.CompleteInteracPSIGateParams.searchBy2
            this.searchValue = this.$route.params.CompleteInteracPSIGateParams.searchValue
            if(this.searchValue && this.searchValue.trim() != '') {
                this.showTransaction()
            }else{
                this.showAllTransaction()
            }
        }
    },
    computed: {
        transactionSummaryTable() {
            let transactionSummary = []
            if (this.transactionSummary && this.transactionSummary.length > 0) {
                transactionSummary = JSON.parse(JSON.stringify(this.transactionSummary))
                if (this.isLazyLoadingEnabled) {
                    let selectedPage = this.$refs.paginationRef.selectedPage
                    transactionSummary = transactionSummary.slice((selectedPage - 1) * 10, selectedPage * 10)
                }
                transactionSummary = this.removeUnderScoreText(transactionSummary)
            }
            const updatePaginationRecords = () => {
                if (this.$refs.paginationRef) {
                    this.$refs.paginationRef.totalRecords = this.transactionSummary?.length ?? 0
                }
            }
            if (this.isLazyLoadingEnabled) {
                updatePaginationRecords()
            }
            return transactionSummary
        }
    }
}
</script>